export default class lazyload {
  constructor(images) {
    if (!images) {
      return;
    }

    this.images = images;
    this.timing = 300

    this.initEvent();
  }

  initEvent() {
    this.intersectionObserver()
  }

  async loadImage(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => reject(false);
      img.src = src;
    })
  }

  intersectionObserver() {
    const imageObserver = new IntersectionObserver((entries, imgObserver) => {
      entries.forEach(async (entry) => {
        if (entry.isIntersecting) {
          const container = entry.target;
          const currentImage = container.querySelector('.lazy-load__image');

          if (currentImage && await this.loadImage(currentImage.dataset.src) === true) {
            currentImage.src = currentImage.dataset.src;
            setTimeout(() => {
              container.classList.remove('lazy-load--placeholder');
              imgObserver.unobserve(container);
            }, this.timing);
          }
        }
      })
    });
    this.images.forEach((img) => imageObserver.observe(img));
  }

}