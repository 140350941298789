export default class menu {
	constructor(container) {
		if (!container) {
			return;
		}

		this.container = container;
		this.btnMobile = this.container.querySelector('.menu__mobile');
		this.banner = document.querySelector('.banner');

		this.initEvent();
	}

	initEvent() {
		this.btnMobile.addEventListener('click', () => this.toggleMobileMenu());
		document.addEventListener('wheel', (event) => this.onScroll(event));
	}

	toggleMobileMenu() {
		this.container.classList.toggle('is-open');
	}

	onScroll(event) {
		const scrollDirectionUp = this.checkScrollDirectionIsUp(event);
		const scrollY = window.scrollY;
		const bannerHeight = this.banner ? this.banner.getBoundingClientRect().height : 500;
		const menuSticky = scrollY > bannerHeight / 3;

		const navbar = document.querySelector('.navbar');

		if (menuSticky) {
			this.container.classList.add('is-sticky');
		}

		if (scrollDirectionUp) {
			this.container.classList.add('is-up');
			this.container.classList.remove('is-down');
			if (navbar) {
				navbar.classList.add('with-menu');
			}

		} else {
			this.container.classList.add('is-down');
			this.container.classList.remove('is-up');

			if (navbar) {
				navbar.classList.remove('with-menu');
			}

		}

		if (scrollY === 0) {
			this.container.classList.remove('is-down');
			this.container.classList.remove('is-up');
			this.container.classList.remove('is-sticky');

			if (navbar) {
				navbar.classList.remove('with-menu');
			}

		}
	}

	checkScrollDirectionIsUp(event) {
		if (event.wheelDelta) {
			return event.wheelDelta > 0;
		}
		return event.deltaY < 0;
	}
}