import 'regenerator-runtime/runtime';
import lazyload from './component/lazy-load';
import menu from './component/menu';
import Parallax from './component/parallax';
import carousel from './component/carousel';
import navbar from './component/navbar';
import accordion from './component/accordion';
import './component/routing';

const LAZY_LOAD = new lazyload(document.querySelectorAll('.lazy-load'));
const MENU = new menu(document.querySelector('.menu'));
const BANNER_PARALLAX = new Parallax(document.querySelectorAll('.js-parallax'));
const CAROUSEL = new carousel(document.querySelectorAll('.swiper'));
const NAVBAR = new navbar(document.querySelector('.navbar'));
const ACCORDION = new accordion(document.querySelectorAll('.accordion'));

// Scroll to
const scrollTo = (element, to, duration) => {
  const start = element.offsetTop
  const change = to - start
  let currentTime = 0
  const increment = 20;


  const animateScroll = () => {
    currentTime += increment;
    const val = easeInOutQuad(currentTime, start, change, duration);
    element.scrollTop = val;
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  }

  animateScroll()
}

// Easing function -> easeInOutQuad
const easeInOutQuad = (t, b, c, d) => {
  t /= d / 2
  if (t < 1) return c / 2 * t * t + b
  t--
  return -c / 2 * (t * (t - 2) - 1) + b
}

const linkMore = document.querySelector('.link-more')
linkMore && linkMore.addEventListener('click', () => {
  const to = document.querySelector('.section-prestation').getBoundingClientRect().top;
  scrollTo(document.documentElement, to, 800);
})