import {
  checkVisible,
  BP_TABLET
} from "../utils";

class Parallax {
  constructor(images, timing, ease) {
    if (!images) {
      return;
    }

    this.timing = timing || '0.1s';
    this.ease = ease || 'cubic-bezier(0.34, 1.56, 0.64, 1)';

    this.images = images;

    if (window.innerWidth >= BP_TABLET) {
      this.initEvent();
    }
  }

  initEvent() {
    window.addEventListener('scroll', () => {
      for (let i = 0; i < this.images.length; i++) {
        const img = this.images[i]
        if (checkVisible(img)) {
          const scrolled = window.pageYOffset;
          const ratio = ((scrolled - img.offsetTop) / 2) * 0.25;
          img.style.transform = `translateY(${ratio}px)`;
          img.style.willChange = 'transform';
          img.style.transition = `transform ${this.timing} ${this.ease}`;
        }
      }
    });
  }
}

export default Parallax;