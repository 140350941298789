export default class accordion {
  constructor(target) {
    if (!target) {
      return;
    }

    this.accordionLinks = target;
    this.initEvent();
  }

  initEvent() {
    this.accordionLinks.forEach((el, index) => {
      el.querySelector('.accordion-link').addEventListener('click', e => {
        e.preventDefault()
        this.handleClick(e, index)
      });
    });
  }

  handleClick(e, index) {
    e.preventDefault();

    this.accordionLinks.forEach(el => {
      if (!el.classList.contains('is-opened')) {
        el.classList.remove('is-opened');
      }
    });

    this.accordionLinks[index].classList.toggle('is-opened');
  }
}