export default class navbar {
  constructor(container) {
    if (!container) {
      return;
    }

    this.navbar = container;
    this.sections = document.querySelectorAll('.section');

    this.initEvent();
  }

  initEvent() {
    document.addEventListener('wheel', () => this.onScroll());
    const links = this.navbar.querySelectorAll('a')
    links.forEach(link => {
      link.addEventListener('click', () => {
        links.forEach(link => link.classList.remove('is-active'));
        setTimeout(() => link.classList.add('is-active'));
      });
    })
  }

  checkVisible(elm) {
    const rect = elm.getBoundingClientRect();
    const viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );
    return !(rect.bottom < 0 || rect.top - viewHeight >= (-rect.height / 2))
  }

  onScroll() {
    this.sections.forEach(section => {
      if (this.checkVisible(section)) {
        const id = section.getAttribute('id');
        const links = this.navbar.querySelectorAll('a')
        links.forEach(link => {
          if (link.getAttribute('href') === `#${id}`) {
            link.classList.add('is-active');
          } else {
            link.classList.remove('is-active');
          }
        })
      }
    })
  }
}