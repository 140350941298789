import Swiper, {
  Navigation,
  Pagination
} from 'swiper';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination]);

export default class carousel {
  constructor(container) {
    if (!container) {
      return;
    }

    this.container = container;
    this.breakpoint = window.matchMedia('(max-width: 767px)');
    this.swiper = [];
    this.breakpointBool = false;

    this.initEvent();
  }

  initEvent() {
    if (this.breakpoint.matches) {
      this.createSwiper()
    }
    window.addEventListener('resize', () => this.resizeListener());
  }

  resizeListener() {
    if (!this.breakpoint.matches) {
      this.breakpointBool = false;
      this.swiper.forEach((el) => {
        el.destroy(true, true);
      })
    } else {
      if (!this.breakpointBool) {
        this.createSwiper();
      }
    }
  }

  createSwiper() {
    this.container.forEach((el) => {
      const swiperContainer = el.querySelector('.swiper-container');
      const swiper = this.initSwiper(swiperContainer);
      this.swiper.push(swiper);
      this.breakpointBool = true;
    });
  }

  initSwiper(swiperContainer) {
    return new Swiper(swiperContainer, {
      speed: 400,
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 32,
      updateOnWindowResize: true,
      centeredSlides: true,
      pagination: {
        el: swiperContainer.querySelector('.swiper-pagination'),
      },
    });
  }
}